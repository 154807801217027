const Maintenance = () => {
  return (
    <main className="py-12 h-screen flex justify-center">
      <div className="w-full grid grid-cols-1 md:grid-cols-2">
        <div className="flex justify-center">
          <img
            src="https://dogetrixchain.ai/static/media/itemslider.95835393270091545d0a.png"
            alt="itemslider"
            width={698}
            height={952}
            className="w-1/2 object-contain"
          />
        </div>
        <div className="flex justify-center flex-col">
          <h2 className="w-full inline-block text-[40px] leading-[50px] md:text-[80px] md:leading-[90px] text-white">
            DOGETRIXCHAINAI
          </h2>
          <h3 className="w-full inline-block text-[30px] leading-[40px] md:text-[60px] md:leading-[70px] text-white">
            We are under maintenance
          </h3>
          <h4 className="mt-3 w-full inline-block text-gray text-[35px] leading-[40px]">
            We will be back soon. Thank you
          </h4>
          <div className="w-full inline-block mt-[50px]">
            <a
              href="https://dogetrixchain.ai/"
              className="mt-8 bg-primary text-white text-[30px] btn-effect py-5 px-7"
            >
              <span className="effect">Website</span>
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Maintenance;
