import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "./accountSlice";
import loadingReducer from "./loadingSlice";
import signatureReducer from "./signatureSlice";

export const store = configureStore({
  reducer: {
    account: accountReducer,
    loading: loadingReducer,
    signature: signatureReducer,
  },
});
