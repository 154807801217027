import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  signature: '',
};

export const signatureSlice = createSlice({
  name: "signature",
  initialState,
  reducers: {
    setSignature: (state, action) => {
      state.signature = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSignature } = signatureSlice.actions;

export default signatureSlice.reducer;
